const ROUTES = {
	"/profile" : { object : "", action : "" },
	"/users" : { object : "users", action : "list" },
	"/users/:userId" : { object : "users", action : "view" },
	"/users/:client/:clientId" : { object : "inner-clients", action : "view" },
	"/sendings" : { object : "sendings", action : "list", variant : "road" },
	"/sendings/avia" : { object : "sendings", action : "list", variant : "air" },
	"/sendings/:sendingId" : { object : "sendings", action : "view" },
	"/sendings/:sendingId/:placeId" : { object : "places", action : "view" },
	"/services/issuance" : { object : "issuance", action : "list" },
	"/services/issuance/:serviceId" : { object : "issuance", action : "view" },
	"/services/delivery" : { object : "delivery", action : "list" },
	"/services/delivery/:serviceId" : { object : "delivery", action : "view" },
	"/services/fullfillment" : { object : "fullfillment", action : "list" },
	"/services/fullfillment/:serviceId" : { object : "fullfillment", action : "view" },
	"/services/storage" : { object : "storage", action : "list" },
	"/services/storage/:serviceId" : { object : "storage", action : "view" },
	"/services/repack" : { object : "repack", action : "list" },
	"/services/repack/:serviceId" : { object : "repack", action : "view" },
	"/dictionary/config" : { object : "config", action : "view" },
	"/dictionary/currency" : { object : "currency", action : "list" },
	"/dictionary/currency/:currencyId" : { object : "currency", action : "view" },
	"/clients" : { object : "clients", action : "list" },
	"/clients/:userId" : { object : "clients", action : "view" },
	"/employees" : { object : "employees", action : "list" },
	"/employees/:userId" : { object : "employees", action : "view" },
	"/dictionary/rates" : { object : "rates", action : "list" },
	"/dictionary/rates/:itemId" : { object : "rates", action : "view" },
	"/dictionary/rates/:rateId/:categoryId" : { object : "product-categories", action : "view" },
	"/dictionary/drivers" : { object : "drivers", action : "list" },
	"/dictionary/drivers/:itemId" : { object : "drivers", action : "view" },
	"/templates" : { object : "", action : "" },
	"/templates/:templateId" : { object : "", action : "" },
	"/client-invoices" : { object : "client-invoices", action : "list" },
	"/client-invoices/:invoiceId" : { object : "client-invoices", action : "view" },
	"/client-payments" : { object : "client-payments", action : "list" },
	"/client-payments/:paymentId" : { object : "client-payments", action : "view" },
	"/client-balance" : { object : "client-balance", action : "view" },
	"/drivers-invoices" : { object : "drivers-invoices", action : "list" },
	"/drivers-invoices/:invoiceId" : { object : "drivers-invoices", action : "view" },
	"/drivers-payments" : { object : "drivers-payments", action : "list" },
	"/drivers-payments/:paymentId" : { object : "drivers-payments", action : "view" },
	"/drivers-balance" : { object : "drivers-balance", action : "view" },
	"/company-income" : { object : "company-income", action : "list" },
	"/company-income/:orderId" : { object : "company-income", action : "view" },
	"/company-cost" : { object : "company-cost", action : "list" },
	"/company-cost/:orderId" : { object : "company-cost", action : "view" },
	"/company-balance" : { object : "company-balance", action : "view" },
	"/groups" : { object : "groups", action : "view" }
};

export default ROUTES;