import { useState, useMemo } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Form, Button, Col, Row, Table, Typography, message, Select } from 'antd'
import { get, set } from 'lodash'
import { SaveOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { getFieldsByRole, getCommonFields, companyFields } from './forms'
import Wrapper from '../../components/Wrapper'
import DeleteButton from '../../components/DeleteButton'
import SelectCity from '../../components/SelectCity'
import FormField from '../../components/FormField'
import axios from '../../utils/axios'
import { useUsers, createUser, updateUserById } from '../../utils/api'
import { getKeyFromName } from '../../utils/utils'
import { VALIDATION_MESSAGES, COLUMNS, USER_ROLES, USER_ROLES_PLURAL } from '../../config/consts'
import Groups from '../../stores/Groups'

const EMPTY_OBJECT = {}

const inclientColumns = [ 
  COLUMNS.code,
  COLUMNS.name,
  COLUMNS.phone,
  COLUMNS.company
]

const userRoles = { '' : 'Пользователь', ...USER_ROLES }
const userRolesPlural = { '' : 'Пользователи', ...USER_ROLES_PLURAL }

const userLinks = {
  '' : '/users',
  '1': '/clients',
  '2': '/employees',
  '4': '/users'
}

export default function PageUser({ userId, mode = '' }) {
  const [ searchParams ] = useSearchParams()
  const [ messageApi, contextHolder ] = message.useMessage()
  const [ form ] = Form.useForm()
  const navigate = useNavigate()
  const [ isMakingPass, setIsMakingPass ] = useState(false)
  const [ isSending, setIsSending ] = useState()
  const queryClient = useQueryClient()
  const users = useUsers(userId, '')
  const profile = users.data || EMPTY_OBJECT

  const isNew = userId === 'create'

  const role = isNew ? ( searchParams.get( 'role' ) || mode ) : profile.id_role
  const inclients = useUsers({ id_role: '3', create_user: userId }, '', { enabled: role === '1' })
  const title =
    isNew ?
    ( 'Новый ' + userRoles[ role ].toLowerCase() ) :
    `${userRoles[profile.id_role]} ${profile.json?.code}`

  const breadcrumbs = useMemo(() => {
    const crumbs = [{ title: <Link to={userLinks[ mode ]}>{userRolesPlural[ mode ]}</Link> }]
    crumbs.push({ title })
    return crumbs
  }, [mode, title])
  
  const groupsStore = Groups.useValue()
  const userGroups =
    groupsStore.lastLoad > 0 ?
    Object.entries( groupsStore.groups )
      .map( ([ id, { name } ]) => ({ key : id, value : id, label : name }) ) :
    []
  const [ selectedGroup, selectGroup ] = useState( 0 )
  const [ profileGroups, setProfileGroups ] = useState( null )
  let actualProfileGroups = profileGroups;
  if (!actualProfileGroups) {
    if (role === '3' || role === '4') {
      actualProfileGroups = []
    }
    else if (groupsStore.lastLoad > 0) {
      if (isNew) {
        const defaultGroup = userGroups.find( item => item.label === userRoles[ role ] )?.value
        actualProfileGroups = defaultGroup ? [{ key : defaultGroup }] : []
      }
      else if (users.isSuccess) {
        actualProfileGroups = profile.json?.groups?.map( group => ({ key : `${group}` }) ) || []
        if (groupsStore.lastLoad > 0) actualProfileGroups = actualProfileGroups.filter( ({ key }) => userGroups.findIndex( group => group.key === key ) >= 0 )
      }
    }
    if (actualProfileGroups) setProfileGroups( actualProfileGroups )
  }
  const [ groupsFiltered, setGroupsFiltered ] = useState( profileGroups && groupsStore.lastLoad > 0 )
  if (!isNew && users.isSuccess && !actualProfileGroups) {
    actualProfileGroups = profile.json?.groups?.map( group => ({ key : `${group}` }) ) || []
    setProfileGroups( actualProfileGroups )
  }
  if (!groupsFiltered && actualProfileGroups && groupsStore.lastLoad > 0) {
    actualProfileGroups = actualProfileGroups.filter( ({ key }) => userGroups.findIndex( group => group.key === key ) >= 0 )
    setProfileGroups( actualProfileGroups )
    setGroupsFiltered( true )
  }
  const addGroup = () => {
    if (actualProfileGroups && selectedGroup && actualProfileGroups.findIndex( ({ key }) => key === selectedGroup ) < 0) {
      setProfileGroups([ ...actualProfileGroups, { key : selectedGroup } ])
    }
  }
  const deleteGroup = evt => {
    if (actualProfileGroups) {
      const groupId = evt.target.closest('button')?.dataset?.group
      if (groupId) {
        const idx = actualProfileGroups.findIndex( ({ key }) => key === groupId )
        if (idx > 0) {
          setProfileGroups([ ...actualProfileGroups.slice( 0, idx - 1 ), ...actualProfileGroups( idx + 1 ) ])
        }
        else if (idx === 0) {
          setProfileGroups([ ...actualProfileGroups.slice( 1 ) ])
        }
      }
    }
  }
  const groupsColumns = [
    {
      render( group ) { return groupsStore.groups[ group.key ]?.name }
    },
      {
      width : 40,
      render( group ) {
        return <Button
          type='text'
          size='small'
          icon=<DeleteOutlined />
          danger
          title='Удалить группу'
          data-group={group.key}
          onClick={deleteGroup}
        />
      }
    }
  ]

  const columns = useMemo(() => [
    ...inclientColumns,
    {
      title: '',
      dataIndex: 'buttons',
      render: (_, item) => (<DeleteButton
        confirm='Вы действительно хотите удалить этого внутреннего клиента?'
        onOk={async () => {
          await axios.postWithAuth('/query/update', { sql: `UPDATE users SET deleted=1 WHERE id_user=${item.id_user}` })
          inclients.refetch()
        }}
      />)
    }
  ], [inclients])

  if (users.isLoading) return null

  return (
    <Wrapper
      title={title}
      breadcrumbs={breadcrumbs}
      buttons={[
        !isNew && role !== '3' && <Button
          key='1'
          style={{ marginRight: 20 }}
          size='large'
          htmlType='button'
          onClick={async () => {
            setIsMakingPass(true)
            await axios.postWithAuth('/remind', { u_email: profile.email })
            message.info('Пароль для входа отправлен на e-mail')
            setIsMakingPass(false)
          }}
          loading={isMakingPass}
        >
          Сбросить пароль
        </Button>,
        <Button
          key='2'
          icon={<SaveOutlined />}
          type='primary'
          htmlType='submit'
          style={{ marginRight: 10 }}
          size='large'
          onClick={() => form.submit()}
          disabled={isSending}
        >
          Сохранить
        </Button>
      ].filter(Boolean)}
    >
      <Form
        initialValues={profile}
        layout='vertical'
        size='large'
        validateMessages={VALIDATION_MESSAGES}
        form={form}
        autoComplete='off'
        onFinish={async (values) => {
          values.id_role = role
          const fields = getFieldsByRole(values.id_role)
          fields.forEach(field => {
            const value = get(values, field.name)
            if (value) {
                set(values, field.name, value.replaceAll('_', ''))
            }
          })

         
          if (role !== '3' && role !== '4') values.json.groups = ( actualProfileGroups || [] ).map( item => Number( item.key ) )
          values.json = JSON.stringify(values.json)

          setIsSending(true)
          try {
            if (isNew) {
              const lastId = await createUser(values)
              navigate(`${userLinks[ mode ]}/${lastId}`)
              messageApi.success('Пользователь успешно создан')
            } else {
              await updateUserById(userId, values)
              
              messageApi.success('Пользователь успешно обновлен')
              queryClient.invalidateQueries([ 'users', userId, '' ])
            }
          } catch (e) {
            messageApi.error(e.message)
          } finally {
            setIsSending(false)
          }
        }}
      >
        <Row
          gutter={16}
          style={{ margin: 10 }}
        >
          {getCommonFields(profile, isNew).map(({ span, ...field }) => (
            <Col span={span} key={getKeyFromName(field.name)}>
              <FormField
                {...field}
              />
            </Col>
          ))}
        </Row>
        { !mode && role !== '3' && role !== '4' && (
          <>
            <Row gutter={16} style={{ margin: 10 }} >
              <Col>
                <Typography.Text strong>Группы</Typography.Text>
              </Col>
            </Row>
            <Row gutter={16} style={{ margin: 10 }} >
              <Col span={8}>
                <Table
                  isLoading={ !actualProfileGroups || groupsStore.lastLoad <= 0 }
                  size='small'
                  tableLayout='fixed'
                  pagination={false}
            style={{ width : '100%' }}
            showHeader={ false }
            columns={groupsColumns}
                  dataSource={ actualProfileGroups || [] }
                />
              </Col>
              <Col span={16}>
                <Row gutter={8}>
                  <Col span={8}>
                    <Select options={userGroups} style={{ width : '100%' }} onChange={ val => selectGroup( val ) } />
                  </Col>
                  <Button icon=<PlusOutlined /> title="Добавить группу" disabled={selectedGroup===0} onClick={addGroup} />
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Form.Item dependencies={['id_role']}>
          {({ getFieldValue }) => {
            const fields = getFieldsByRole(getFieldValue('id_role'))
            return (
              <Row
                gutter={16}
                style={{ margin: 10 }}
              >
                {fields.map(({ span, ...field }) => (
                  field.type === 'city' ? 
                    <SelectCity
                      key={getKeyFromName(field.cityName)}
                      span={span}
                      defaultCountry={profile?.json?.country}
                      form={form}
                      showErrorMessage={messageApi.error}
                      {...field}
                    />
                    :
                    <Col span={span} key={getKeyFromName(field.name)}>
                      <FormField
                        {...field}
                        rules={[
                          ...(field.rules || []),
                          { required: field.required }
                        ]}
                        size='large'
                      />
                    </Col>
                ))}
              </Row>
            )
          }}
        </Form.Item>
        {role === '1' && <fieldset style={{ padding: 10, margin: '20px 10px' }}>
          <legend>Юридическое лицо</legend>
          <Row gutter={16}>
            {companyFields.map(({ span, ...field }) => (
              <Col span={span} key={getKeyFromName(field.name)}>
                <FormField
                  {...field}
                  rules={[
                    ...(field.rules || []),
                    { required: field.required }
                  ]}
                  size='large'
                />
              </Col>
            ))}
          </Row>
        </fieldset>}
      </Form>
      {role === '1' && !isNew && <>
        <Row align='middle' style={{ padding: '0 40px' }}>
          <Col span={12}>
            <Typography.Title style={{ fontWeight: 'bold' }}>Внутренние клиенты</Typography.Title>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 20 }}
              type='primary'
              size='large'
              onClick={() => navigate(`/users/${userId}/create`, { state: { clientId: userId }})}
            >
              Создать
            </Button>
          </Col>
        </Row>
        <Table
          size='small'
          columns={columns}
          isLoading={inclients.isLoading}
          dataSource={inclients.data}
          onRow={record => ({
            onClick: (e) => {
              if (e.detail === 2) {
                navigate(`/users/${userId}/${record.id_user}`)
              }
            },
          })}
        />
      </>}
      {contextHolder}
    </Wrapper>
  )
}