import PageUsers from '../pages/Users'
import PageUser from '../pages/User'
import PageInclient from '../pages/User/inclient'
import Sendings from '../pages/Sendings'
import Sending from '../pages/Sending'
import ServiceForm from '../pages/ServiceForm'
import ServiceList from '../pages/ServiceList'
import Dictionary from '../pages/Dictionary'
import DictionaryForm from '../pages/Dictionary/form'
import UserForm from '../pages/Dictionary/userForm'
import ConfigForm from '../pages/Dictionary/config'
import CurrencyRate from '../pages/Dictionary/currencyRate'
//~ import Templates from '../pages/Templates'
//~ import Template from '../pages/Template'
import Place from '../pages/Place'
import CompanyBalance from '../pages/CompanyBalance'
import CompanyCost from '../pages/CompanyCost'
import CompanyCostItem from '../pages/CompanyCost/form'
import CompanyIncome from '../pages/CompanyIncome'
import CompanyIncomeItem from '../pages/CompanyIncome/form'
import ClientInvoices from '../pages/ClientInvoices'
import ClientInvoicesForm from '../pages/ClientInvoices/form'
import ClientPayments from '../pages/ClientPayments'
import ClientPaymentsForm from '../pages/ClientPayments/form'
import ClientBalance from '../pages/ClientBalance'
import DriversInvoices from '../pages/DriversInvoices'
import DriversInvoicesForm from '../pages/DriversInvoices/form'
import DriversPayments from '../pages/DriversPayments'
import DriversPaymentsForm from '../pages/DriversPayments/form'
import DriversBalance from '../pages/DriversBalance'
import ProductsCategory from '../pages/Dictionary/products-category'
import CreateProductModal from '../pages/Place/СreateProductModal'

import Groups from '../stores/Groups'

const proto = {
	action( name, title, view ) {
		this.description.actions[ name ] = { title };
		if (view) this[ name ] = () => view;
		else this[ name ] = () => {};
		return this;
	},
	
	variant( name, title ) {
		( this.description.variants ||= {} )[ name ] = title;
		return this;
	},
	
	variants( newVariants ) {
		Object.assign( ( this.description.variants ||= {} ), newVariants );
		return this;
	}
};

function registerEntity( topic, name, title ) {
	const entity = Object.create( proto );
	entity.description = { topic, title, actions : {} };
	ENTITIES[ name ] = entity;
	return entity;
}

const ENTITIES = {
	groups : Groups
};

registerEntity( "Управление пользователями", "users", "Пользователи" )
	.action( "list", "Список", <PageUsers /> )
	.action( "view", "Смотреть", <PageUser /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Управление пользователями", "clients", "Клиенты" )
	.action( "list", "Список", <PageUsers role="1" /> )
	.action( "view", "Смотреть", <PageUser mode="1" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Управление пользователями", "inner-clients", "Внутренние клиенты" )
	.action( "list", "Список" )
	.action( "view", "Смотреть", <PageInclient /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Управление пользователями", "employees", "Сотрудники" )
	.action( "list", "Список", <PageUsers role="2" /> )
	.action( "view", "Смотреть", <PageUser mode="2" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Настройки", "drivers", "Перевозчики" )
	.action( "list", "Список", <Dictionary name="drivers" /> )
	.action( "view", "Смотреть", <DictionaryForm name="drivers" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Настройки", "config", "Параметры учёта" )
	.action( "view", "Смотреть", <ConfigForm /> )
	.action( "modify", "Изменить" );

registerEntity( "Настройки", "rates", "Тарифы перевозок" )
	.action( "list", "Список", <Dictionary name="rates" /> )
	.action( "view", "Смотреть", <DictionaryForm name="rates" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Настройки", "product-categories", "Категории товаров" )
	.action( "list", "Список" )
	.action( "view", "Смотреть", <ProductsCategory /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Настройки", "currency", "Валюта и курс" )
	.action( "list", "Список", <Dictionary name="currency" /> )
	.action( "view", "Смотреть курс", <CurrencyRate /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Отправки", "sendings", "Отправки" )
	.action( "list", "Список", <Sendings /> )
	.action( "view", "Смотреть", <Sending /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" )
	.variants( { road : "Автомобильные", air : "Авиа" } );

registerEntity( "Отправки", "places", "Места" )
	.action( "list", "Список" )
	.action( "view", "Смотреть", <Place /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Отправки", "products", "Товары" )
	.action( "list", "Список" )
	.action( "view", "Смотреть", <CreateProductModal /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Услуги", "issuance", "Выдача со склада" )
	.action( "list", "Список", <ServiceList serviceName="issuance" /> )
	.action( "view", "Смотреть", <ServiceForm serviceName="issuance" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Услуги", "delivery", "Доставка" )
	.action( "list", "Список", <ServiceList serviceName="delivery" /> )
	.action( "view", "Смотреть", <ServiceForm serviceName="delivery" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Услуги", "fullfillment", "Фулфилмент" )
	.action( "list", "Список", <ServiceList serviceName="fullfillment" /> )
	.action( "view", "Смотреть", <ServiceForm serviceName="fullfillment" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Услуги", "storage", "Хранение" )
	.action( "list", "Список", <ServiceList serviceName="storage" /> )
	.action( "view", "Смотреть", <ServiceForm serviceName="storage" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Услуги", "repack", "Переупаковка" )
	.action( "list", "Список", <ServiceList serviceName="repack" /> )
	.action( "view", "Смотреть", <ServiceForm serviceName="repack" /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Финансы", "client-invoices", "Счета клиентов" )
	.action( "list", "Список", <ClientInvoices /> )
	.action( "view", "Смотреть", <ClientInvoicesForm /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Финансы", "client-payments", "Оплаты клиентов" )
	.action( "list", "Список", <ClientPayments /> )
	.action( "view", "Смотреть", <ClientPaymentsForm /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" )
	.variants( { cash : "Наличные", noncash : "Безналичные" } );

registerEntity( "Финансы", "client-balance", "Баланс клиентов" )
	.action( "view", "Смотреть", <ClientBalance /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" )
	.variants( { cash : "Наличные", noncash : "Безналичные" } );

registerEntity( "Финансы", "drivers-invoices", "Счета перевозчиков" )
	.action( "list", "Список", <DriversInvoices /> )
	.action( "view", "Смотреть", <DriversInvoicesForm /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Финансы", "drivers-payments", "Оплаты перевозчиков" )
	.action( "list", "Список", <DriversPayments /> )
	.action( "view", "Смотреть", <DriversPaymentsForm /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" )
	.variants( { cash : "Наличные", noncash : "Безналичные" } );

registerEntity( "Финансы", "drivers-balance", "Баланс перевозчиков" )
	.action( "view", "Смотреть", <DriversBalance /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Финансы", "company-income", "Приход средств" )
	.action( "list", "Список", <CompanyIncome /> )
	.action( "view", "Смотреть", <CompanyIncomeItem /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

registerEntity( "Финансы", "company-cost", "Расход средств" )
	.action( "list", "Список", <CompanyCost /> )
	.action( "view", "Смотреть", <CompanyCostItem /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" )
	.variants( { cash : "Наличные", noncash : "Безналичные" } );

registerEntity( "Финансы", "company-balance", "Баланс компании" )
	.action( "view", "Смотреть", <CompanyBalance /> )
	.action( "modify", "Изменить" )
	.action( "add", "Создать" )
	.action( "delete", "Удалить" );

export default ENTITIES;
