import { useState } from 'react'
import { Col, Row, Button, Form, Input, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { login } from '../../utils/api'
import { API_URL } from '../../config/consts'
export default function PageLogin() {
  const [ isLoading, setIsLoading ] = useState()
  const navigate = useNavigate()

  return (
    <Row style={{ height: '100vh' }} justify="center" align="middle">
      <Col span={8}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, textAlign: 'left' }}
          initialValues={{ remember: true }}
          onFinish={values => {
            setIsLoading(true)
            login(values).then(role => {
              setIsLoading(false)
              navigate('/')
            })
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="login"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        {API_URL.includes('CargoTst') ? 'development mode' : 'production mode'}<br/>
       IS_DEV = {process.env.REACT_APP_IS_DEV}<br/>
  


      </Col>
    </Row>
  )
}