import { hookstate, useHookstate } from '@hookstate/core';

import axios from '../../utils/axios';
import { parseJSON } from '../../utils/utils'
import GroupsView from './views';
import ENTITIES from '../../config/entities.js';

const groupsReloadTime = 1800000; // 30 minute

const groupsStore = hookstate( {
	lastLoad : 0,
	isLoading : false,
	isFailed : false,
	groups : {}
} );

const Groups = {
	description : {
		topic : 'Управление пользователями',
		title : 'Группы',
		detailed : 'Группы пользователей используются для управления доступом к объектам программы',
		actions : {
			'view' : { 'title' : 'Просмотр' },
			'modify' : { 'title' : 'Изменение' },
			'add' : { 'title' : 'Добавление' },
			'delete' : { 'title' : 'Удаление' }
		}
	},
	
	useValue() {
		if (groupsStore.lastLoad.value < Date.now() - groupsReloadTime) this.load();
		return useHookstate( groupsStore ).value;
	},
	
	load() {
		if (groupsStore.isLoading.value) return;
		
		groupsStore.isLoading.set( true );
		return axios.select( 'sprset', '`id`,`pole`', { where: { tip : 'usergroup', status: 0 } } )
			.then( response => {
				if (response?.data?.status !== 'success') throw new Error( 'Error loading user groups' );
				const rows = response.data.data || [];
				const groups = {};
				rows.forEach( item => groups[ item.id ] = parseJSON( item.pole ) );
				groupsStore.merge({ lastLoad : Date.now(), isFailed : false });
				groupsStore.groups.set( groups );
			})
			.catch( () => {
				groupsStore.isFailed.set( true );
				groupsStore.groups.set( {} );
			} )
			.then( () => {
				groupsStore.isLoading.set( false );
				return groupsStore;
			} );
	},
	
	view() {
		return <GroupsView />;
	},
	
	modify( id, name, acl ) {
		if (!isFinite( id ) || !( id = Number( id ) ) || !Number.isInteger( id )) return;
		Object.keys( acl )
			.forEach( key => {
				if (!!ENTITIES[ key ].description.variants) {
					Object.keys( acl[ key ] )
						.forEach( variant => !acl[ key ][ variant ].allow.length && delete acl[ key ][ variant ] );
					if (!Object.keys( acl[ key ] ).length) delete acl[ key ];
				}
				else {
					if (!acl[ key ].allow.length) delete acl[ key ];
				}
			} );
		const pole = JSON.stringify({ name, acl });
		return axios.update( 'sprset', { pole }, "`id`=" + id + " AND `tip`='usergroup' AND `status`=0" )
			.then( response => {
				if (response?.data?.status !== 'success') throw new Error( 'Error updating user group' );
				this.load();
			});
	},
	
	add( name, acl ) {
		Object.keys( acl ).forEach( key => !acl[ key ].allow.length && delete acl[ key ] );
		const values = {
			tip : 'usergroup',
			id_ref : 0,
			ref_tip : '',
			pole : { name, acl },
			creator_id : '{$_SYS[AUTH][u_id]}',
			status : 0
		};
		return axios.insert( 'sprset', values )
			.then( response => {
				if (response?.data?.status !== 'success') throw new Error( 'Error inserting user group' );
				return this.load().then( () => `${response.data.data.id}` );
			});
	},
	
	delete( id ) {
		if (!isFinite( id ) || !( id = Number( id ) ) || !Number.isInteger( id )) return;
		return axios.update( 'sprset', { status : 1 }, "`id`=" + id + " AND `tip`='usergroup' AND `status`=0" )
			.then( response => {
				if (response?.data?.status !== 'success') throw new Error( 'Error deleting user group' );
				this.load();
			});
	}
};

export default Groups;