import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Row, Col, Table, Typography, Switch, Modal, DatePicker, Select, Input } from 'antd'
import { ExclamationCircleFilled, CopyOutlined, DownloadOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import dayjs from 'dayjs'
import { uniq } from 'lodash'
import { BsTrash } from 'react-icons/bs'
import { API_URL, SENDING_STATUS } from '../../config/consts'
import { SendingsStatus } from '../../components/SendingsStatus'
import { DateTableCell } from '../../components/DateTableCell'
import { getCount, getSendings, deleteSendingById, updateSendingById, useDictionary } from '../../utils/api'
import { declOfNum, getPaginationSettings, localeNumber, copySending } from '../../utils/utils'
import { getColumnSearchProps } from '../../utils/components'
import SelectUser from '../../components/SelectUser'
import SelectRate from '../../components/SelectRate'

const { Title, Link } = Typography
export let PropertyGap = 10

const cookies = new Cookies()

export default function Sendings() {
  const navigate = useNavigate()
  const location = useLocation()
  const [ searchParams ] = useSearchParams()
  const [ filters, setFilters ] = useState({})
  const [ activeOnly, setActiveOnly ] = useState(true)
  const isAir = searchParams.get('air') !== null

  const [ showStatusModal, setShowStatusModal ] = useState(false)
  const [ statusModalValue, setStatusModalValue ] = useState()
  const [ statusModalDate, setStatusModalDate ] = useState()
  const [ statusModalItem, setStatusModalItem ] = useState()
  const [ activeRow, setActiveRow ] = useState()
  const { isLoading, data, refetch } = useQuery(['sendings', { isAir }], getSendings(isAir))
  
  const filterOptions = useMemo(() => {
    if (!data) return {}
    return data.reduce((acc, item) => {
      acc.clients = uniq([...acc.clients, ...item.clients])
      acc.rates = uniq([...acc.rates, ...item.rates])
      return acc
    }, {
      clients: [],
      rates: []
    })
  }, [data])

  const drivers = useDictionary('drivers')
  const [ , driverMap ] = useMemo(() => {
    if (!Array.isArray(drivers.data?.list)) return [[], {}]
    const options = drivers.data.list.map(({ pole = {}, ...item }) => ({
      value: item.id,
      label: item.label
    }))
    const map = options.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {})
    return [ options, map ]
  }, [drivers.data])

  let sendings = (data || [])
    .filter(item => activeOnly ? item.status !== 3 : item.status === 3)
    .filter(item => {
      let res = true
      if (filters.client) res = (item.clients || []).includes(filters.client)
      if (filters.rate) res = res && (item.rates || []).includes(filters.rate)
      if (filters.mark) res = res && (item.labels || []).some(item => (item || '').toLowerCase().includes(filters.mark.toLowerCase()))
      if (filters.article) res = res && (item.articles || []).some(item => (item || '').toLowerCase().includes(filters.article.toLowerCase()))
      return res
    })
    .map(item => {
      const isMaking = item.status === 0
      return {
        ...item,
        'departure-date': <DateTableCell date={new Date(item.departure)} />,
        'delivery-date': <DateTableCell date={new Date(item.delivery)} />,
        buttons: (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            {item.status === 2 && <Button
              type='primary'
              size='small'
              style={{ marginTop: 5 }}
              onClick={() => navigate('/drivers-invoices/create', { state: { type: 'sending', id: item.id } })}
            >
              Создать счет
            </Button>}
            <form target='_blank' method='POST' action={`${API_URL}/script/template/1`}>
              <input type='hidden' value={`{"t_id": ${item.id}, "cf": "vigruzka", "sf": "0", "da": 0 }`} name='s_t_data' />
              <input type='hidden' value={cookies.get('token')} name='token' />
              <input type='hidden' value={cookies.get('u_hash')} name='u_hash' />
              <button type='submit' style={{ background: 'none', border: 0, outline: 'none', padding: 0, cursor: 'pointer' }}>
                <DownloadOutlined
                  size={17}
                  color='#1677ff'
                />
              </button>
            </form>
            <CopyOutlined
              size={17}
              color='#141414'
              onClick={async () => {
                const newId = await copySending(item.id)
                navigate(`/sendings/${newId}`)
              }}
            />
            <BsTrash
              style={{ cursor: isMaking ? 'pointer' : 'auto' }}
              size={17}
              color={isMaking ? 'red' : '#ccc'}
              title={!isMaking && 'Удалить можно только отправку со статусом «Формирование»'}
              onClick={async () => {
                if (!isMaking) return
                const count = await getCount('dataset', { tip: 'place', id_ref: item.id })
                Modal.confirm({
                  title: 'Вы действительно хотите удалить эту отправку?',
                  icon: <ExclamationCircleFilled />,
                  content: count > 0 && <div>
                    К этой отправке {declOfNum(count, ['привязана', 'привязано', 'привязано'])} {count}&nbsp;
                    {declOfNum(count, ['запись', 'записи', 'записей'])} о местах, {count === '1' ? 'которая' : 'которые'} так же&nbsp;
                    {count === '1' ? 'будет удалена' : 'будут удалены'}
                  </div>,
                  okText: 'Да',
                  okType: 'danger',
                  cancelText: 'Нет',
                  onOk: () => deleteSendingById(item.id).then(() => refetch())
                })
              }}
            />
          </div>
        ),
      }
    })

  const handleClickStatus = (item) => {
    setStatusModalValue(item.status)
    setStatusModalItem(item)
    setStatusModalDate(dayjs())
    setShowStatusModal(true)
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'code',
      key: 'code',
      align: 'right',
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: 'Отправка',
      dataIndex: 'departure',
      key: 'departure',
      align: 'center',
      render: (date, item) => item.json?.status > 0 && !!item.json?.status_date_1 && dayjs(item.json?.status_date_1).format('DD.MM.YYYY'),
      sorter: (a, b) => new Date(a.departure).getTime() - new Date(b.departure).getTime(),
      ...getColumnSearchProps('departure', { type: 'date' })
    },
    {
      title: 'Поступление',
      dataIndex: 'delivery',
      key: 'delivery',
      align: 'center',
      render: (date, item) => item.json?.status > 1 && !!item.json?.status_date_2 && dayjs(item.json?.status_date_2).format('DD.MM.YYYY'),
      sorter: (a, b) => new Date(a.delivery).getTime() - new Date(b.delivery).getTime(),
      ...getColumnSearchProps('delivery', { type: 'date' })
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => <SendingsStatus
        status={status}
        onClick={() => handleClickStatus(record)}
      />,
      sorter: (a, b) => a.status - b.status,
      ...getColumnSearchProps(record => record.status + 1, { options: SENDING_STATUS.map((label, value) => ({ value: value + 1, label })) })
    },
    {
      title: 'Мест',
      dataIndex: 'places_count',
      key: 'places_count',
      align: 'right',
      sorter: (a, b) => a.places_count - b.places_count,
      ...getColumnSearchProps('places_count', { type: 'number' })
    },
    {
      title: 'Брутто',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      align: 'right',
      render: val => Number(val) ? localeNumber(Number(val).toFixed(3)) : '',
      sorter: (a, b) => a.gross_weight - b.gross_weight,
      ...getColumnSearchProps('gross-weight', { type: 'number' })
    },
    {
      title: 'Перевозчик',
      dataIndex: 'transporter',
      key: 'transporter',
      render: val => driverMap && driverMap[val],
      sorter: (a, b) => a.transporter.localeCompare(b.transporter),
      ...getColumnSearchProps('transporter')
    },
    {
      title: 'Примечание',
      dataIndex: ['json', 'note'],
      render: note => <div style={{ maxWidth: 80, maxHeight: 55, overflow: 'hidden', textOverflow: 'ellipsis' }} title={note}>{note}</div>
    },
    {
      title: '',
      dataIndex: 'buttons',
      key: 'buttons',
    },
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 40px',
        gap: '20px',
      }}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Typography>
          <Title
            level={1}
            style={{ fontWeight: '700', marginBottom: '0' }}
          >
            Отправки
          </Title>

          <Link
            onClick={() => navigate(`/sendings`)}
            style={{ color: 'blue' }}
          >
            Отправка товаров
          </Link>
        </Typography>
        <Switch
          style={{
            marginBottom: 20,
            transform: 'scale(140%)',
            marginRight: 20,
          }}
          checkedChildren='Активные'
          unCheckedChildren='Завершенные'
          checked={activeOnly}
          onChange={setActiveOnly}
        />
      </Row>
      <Row>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            gap: '20px',
            width: '100%',
          }}
        >
          <Row style={{ width: 800 }} gutter={10}>
            <Col span={6}>
              <SelectUser
                role='1'
                ids={filterOptions.clients}
                placeholder='Клиент'
                onChange={val => setFilters({ ...filters, client: val })}
                clearable
              />
            </Col>
            <SelectRate
              span={6}
              placeholder='Тариф'
              ids={filterOptions.rates}
              onChangeRate={val => setFilters({ ...filters, rate: val })}
              showTarif={false}
              withLabel={false}
              clearable
            />
            <Col span={6}>
              <Input
                placeholder='Марка'
                onChange={(e) => setFilters({ ...filters, mark: e.target.value })}
              />
            </Col>
            <Col span={6}>
              <Input
                placeholder='Артикул'
                onChange={(e) => setFilters({ ...filters, article: e.target.value })}
              />
            </Col>
          </Row>
          <Button
            type='primary'
            onClick={() => {
              navigate(location.pathname + `/create${isAir ? '?air' : ''}`)
            }}
            size={'large'}
          >
            Создать
          </Button>
        </div>
      </Row>
      <Table
        size='small'
        columns={columns}
        dataSource={sendings}
        loading={isLoading}
        rowKey={({ id }) => id}
        rowClassName={(r, index) => index === activeRow ? 'active-row' : ''}
        onRow={(record, index) => ({
          onClick: (e) => {
            if (e.detail === 2) {
              navigate(`/sendings/${record.id}`)
            } else {
              setActiveRow(index)
            }
          },
        })}
        pagination={getPaginationSettings('sendings')}
      />
      <Modal
        title='Изменить статус отправки'
        open={showStatusModal}
        width={300}
        onOk={async () => {
          if (!statusModalItem) return
          const json = {
            ...statusModalItem.json,
            status: statusModalValue,
            [`status_date_${statusModalValue}`]: statusModalDate.format('YYYY-MM-DD')
          }
          await updateSendingById(statusModalItem.id, { json: JSON.stringify(json) })
          refetch()
          setShowStatusModal(false)
        }}
        onCancel={() => setShowStatusModal(false)}
      >
        <DatePicker
          size='large'
          value={statusModalDate}
          onChange={val => setStatusModalDate(val)}
          format='DD.MM.YYYY'
          style={{ width: '100%' }}
        />
        <br />
        <Select
          style={{ width: '100%', marginTop: 10 }}
          size='large'
          options={SENDING_STATUS.filter((_, i) => statusModalItem?.json?.status !== 0 || i !== 2).map((label, value) => ({ label, value }))}
          value={statusModalValue}
          onChange={val => setStatusModalValue(val)}
        />
      </Modal>
    </div>
  )
}
