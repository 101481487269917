import { useEffect, useMemo } from 'react'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { AppRoutes } from './utils/access'
import { useAuthorization } from './utils/api'
import './App.css'

const cookies = new Cookies()

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const isLoginPage = location.pathname === '/login'

  const token = cookies.get('token')
  const u_hash = cookies.get('u_hash')
  const user = useAuthorization({ token, u_hash })

  useEffect(() => {
    if (user.isLoading) return
    const role = user.data?.u_role
    if (!role) {
      navigate('/login', { replace: true })
    } else if (isLoginPage) {
      navigate('/', { replace: true })
    }
  }, [user.isLoading, user.data?.u_role, isLoginPage, navigate])

  //~ const haveAccess = useMemo(() => {
    //~ const access = role.data?.json
    //~ if (!access) return
    //~ if (location.pathname === '/') return true
    //~ return Object.keys(access).some(key => access[key] && location.pathname.startsWith(key))
  //~ }, [role.data, location])

  //~ if (haveAccess === false && !role.isLoading) {
    //~ return (
      //~ <Row style={{ height: '100vh' }} justify='center' align='middle'>
        //~ Доступ к странице ограничен
      //~ </Row>
    //~ )
  //~ }

  if ((user.isLoading || !user.data?.authorized) && !isLoginPage) {
    return (
      <Row style={{ height: '100vh' }} justify='center' align='middle'>
        <LoadingOutlined style={{ fontSize: '64px' }} />
      </Row>
    )
  }

  if ((user.isLoading || !user.data?.authorized) && !isLoginPage) {
    return (
      <Row style={{ height: '100vh' }} justify='center' align='middle'>
        <LoadingOutlined style={{ fontSize: '64px' }} />
      </Row>
    )
  }
  
  return (
    <div className='App'>
      <AppRoutes />
    </div>
  )
}

export default App
