import { cloneElement } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';

import Cookies from 'universal-cookie';
import { Menu } from 'antd';
import * as icons from '@ant-design/icons';

import { useAuthorization } from './api';
import PageLogin from '../pages/Login'
import Layout from '../components/Layout'
import entities from '../config/entities';
import routes from '../config/routes';
import mainMenu from '../config/main-menu';

const cookies = new Cookies();

export function getView( object, action ) {
	const element = entities[ object ]?.[ action ]?.();
	
	if (!element) return () => null;
	return ( props ) => {
		const token = cookies.get('token');
		const u_hash = cookies.get('u_hash');
		const user = useAuthorization({ token, u_hash });
		const params = useParams();
		
		return cloneElement( element, { ...props, user, ...params } );
	};
}

export function AppRoutes() {
	const token = cookies.get('token');
	const u_hash = cookies.get('u_hash');
	const user = useAuthorization({ token, u_hash });
	
	const getRoute = ( path, object, action, variant ) => {
		const View = () => cloneElement( entities[ object ][ action ]( variant ? { variant } : null ), { user, ...( useParams() ) } );
		return <Route key={ path } path={ path } element={ <View /> } />;
	};
	
	return (
		<Routes>
			<Route path='/' element={<Layout user={user.data} />}>
				{/*<Route path='/profile' element={<UserForm name={user.data?.u_role === '1' ? 'employees' : 'clients'} userId={user.data?.u_id} />} />*/}
				{
					Object.entries( routes )
						.filter( ([ path, route ]) => 'function' === typeof entities[ route.object ]?.[ route.action ] )
						.map( ([ path, route ]) => getRoute( path, route.object, route.action, route.variant ) )
				}
			</Route>
			<Route path='/login' element={<PageLogin />} />
		</Routes>
	);
};

function createMenuItems( objects, menuData ) {
	const items = [];
	menuData.forEach( item => {
		let { key, label, icon } = item;
		const Icon = icon ? icons[ icon ] : null;
		if (item.object && item.action && objects[ item.object ][ item.action ][ item.variant ]) {
			label = <Link to={ objects[ item.object ][ item.action ][ item.variant ] }>{ label }</Link>;
		}
		items.push( { key, label, ...( Icon && { icon : <Icon /> } ), ...( item.children && { children : createMenuItems( objects, item.children ) } ) } );
	} );
	return items;
};

export function MainMenu() {
	const objects = {};
	Object.entries( routes ).forEach( ([ route, { object, action, variant = '' } ]) => {
		if (object && action) {
			( ( objects[ object ] ||= {} )[ action ] ||= {} )[ variant ] = route;
		}
	} );
	
	return <Menu items={ createMenuItems( objects, mainMenu ) } theme='dark' mode='inline' />;
};