import { useState, useCallback, useMemo, useEffect } from 'react'
import { Row, Col, Button, Typography, Table, Modal, DatePicker, Select, Switch } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { BsTrash } from 'react-icons/bs'
import dayjs from 'dayjs'
import { useService, updateDatasetById, useUsers, checkSendingFinished } from '../../utils/api'
import { SERVICE_STATUS, SERVICE_NAME, MARKETPLACES } from '../../config/consts'
import { getColumnSearchProps } from '../../utils/components'
import { localeCompare, localeNumber } from '../../utils/utils'

const getEndDateTitle = name => {
  if (name === 'fullfillment') return 'Дата передачи'
  return 'Дата выдачи'
}

const getColumns = (name, { onStatusClick, clients = {} }) => {
  const commonColumns = [
    {
      title: 'Номер',
      dataIndex: 'number',
      sorter: (a, b) => a.number - b.number,
      ...getColumnSearchProps('number', { type: 'number' })
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      align: 'center',
      render: date => dayjs(date).format('DD.MM.YYYY'),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      ...getColumnSearchProps('date', { type: 'date' })
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      render: num => clients[num]?.code,
      sorter: (a, b) => Number(clients[a.client]?.code) - Number(clients[b.client]?.code),
      ...getColumnSearchProps('client')
    },
    name === 'fullfillment' && {
      title: 'Маркетплейс',
      dataIndex: 'marketplace',
      sorter: (a, b) => localeCompare(a.marketplace, b.marketplace),
      ...getColumnSearchProps('marketplace', { options: MARKETPLACES })
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (val, item) => (
        <>
          {SERVICE_STATUS[name][val]} <Button
            onClick={() => onStatusClick(item)}
            size='small'
            type='primary'
            ghost
          >
            Изменить
          </Button>
        </>
      ),
      sorter: (a, b) => localeCompare(a.status, b.status),
      ...getColumnSearchProps('status')
    },
    name === 'storage' && {
      title: 'Дата начала',
      dataIndex: 'start_date',
      align: 'center',
      render: date => date && dayjs(date).format('DD.MM.YYYY'),
      sorter: (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
      ...getColumnSearchProps('start_date', { type: 'date' })
    },
    name === 'storage' && {
      title: 'Дата окончания',
      dataIndex: 'end_date',
      align: 'center',
      render: date => date && dayjs(date).format('DD.MM.YYYY'),
      sorter: (a, b) => new Date(a.end_date).getTime() - new Date(b.end_date).getTime(),
      ...getColumnSearchProps('end_date', { type: 'date' })
    },
    name !== 'storage' && {
      title: getEndDateTitle(name),
      align: 'center',
      dataIndex: `date_status_${SERVICE_STATUS[name].length - 1}`,
      render: (val, item) => item.pole?.is_finished ? val : '',
      sorter: (a, b) => new Date(a[`date_status_${SERVICE_STATUS[name].length - 1}`]).getTime() - new Date(b[`date_status_${SERVICE_STATUS[name].length - 1}`]).getTime(),
      ...getColumnSearchProps(`date_status_${SERVICE_STATUS[name].length - 1}`, { type: 'date' })
    },
    name === 'delivery' && {
      title: 'Тип доставки',
      dataIndex: 'delivery_type',
      sorter: (a, b) => localeCompare(a.delivery_type, b.delivery_type),
      ...getColumnSearchProps('delivery_type')
    },
    {
      title: 'Мест',
      dataIndex: 'places',
      align: 'right',
      render: places => Array.isArray(places) ? places.length : 0,
      sorter: (a, b) => a.places?.length - b.places?.length,
      ...getColumnSearchProps(item => item.places?.length, { type: 'number' })
    },
    {
      title: 'Вес брутто',
      dataIndex: 'placeData',
      align: 'right',
      render: data => localeNumber((data.reduce((sum, item) => sum + (item?.pole?.gross_weight || 0), 0)).toFixed(3)),
      sorter: (a, b) => a.placeData.reduce((sum, item) => sum + (item?.pole?.gross_weight || 0), 0) - b.placeData.reduce((sum, item) => sum + (item?.pole?.gross_weight || 0), 0),
      ...getColumnSearchProps(item => item.placeData.reduce((sum, item) => sum + (item?.pole?.gross_weight || 0), 0), { type: 'number' })
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      render: note => <div style={{ maxWidth: 80, maxHeight: 55, overflow: 'hidden', textOverflow: 'ellipsis' }} title={note}>{note}</div>,
      sorter: (a, b) => localeCompare(a.note, b.note),
      ...getColumnSearchProps('note')
    },
    {
      title: '',
      dataIndex: 'buttons',
      key: 'buttons',
    }
  ].filter(Boolean)

  return commonColumns
}

export default function ServiceList({ serviceName }) {
  const navigate = useNavigate()
  const [ showStatusModal, setShowStatusModal ] = useState(false)
  const [ statusModalValue, setStatusModalValue ] = useState()
  const [ statusModalDate, setStatusModalDate ] = useState()
  const [ statusModalItem, setStatusModalItem ] = useState()
  const [ isActive, setIsActive ] = useState(true)
  const clients = useUsers({ id_role: '1' })
  const { data, isLoading, refetch } = useService(serviceName)

  const handleClickStatus = useCallback((item) => {
    setStatusModalValue(item.status)
    setStatusModalItem(item)
    setStatusModalDate(dayjs())
    setShowStatusModal(true)
  }, [])

  const clientsMap = useMemo(() => {
    return (clients.data || []).reduce((acc, item) => {
      return {
        ...acc,
        [item.id_user]: {
          code: item.json?.code,
        }
      }
    }, {})
  }, [clients.data])

  const columns = useMemo(
    () => getColumns(serviceName, { onStatusClick: handleClickStatus, clients: clientsMap }),
    [serviceName, handleClickStatus, clientsMap]
  )

  const serviceData = (data || [])
    .filter(item => Number(!item.is_finished) === Number(isActive))
    .map(item => {
      return {
        ...item,
        buttons: (
          <div style={{ display: 'flex', gap: 10 }}>
            {['delivery', 'fullfillment', 'storage'].includes(serviceName) && <Button
              type='primary'
              size='small'
              style={{ marginTop: 5 }}
              onClick={() => navigate('/client-invoices/create', { state: { type: serviceName, id: item.id } })}
            >
              Создать счет
            </Button>}
            {(SERVICE_STATUS[serviceName]?.indexOf(item.status) === 0 || !item.status) && <BsTrash
              style={{ marginLeft: 30, cursor: 'pointer' }} 
              size={17}
              color='red'
              title='Удалить услугу'
              onClick={() => {
                Modal.confirm({
                  title: 'Вы действительно хотите удалить эту услугу?',
                  icon: <ExclamationCircleFilled />,
                  okText: 'Да',
                  okType: 'danger',
                  cancelText: 'Нет',
                  onOk: async () => {
                    const pole = {
                      ...item.pole,
                      is_finished: 1
                    }
                    await updateDatasetById(item.id, { status: 1, pole: JSON.stringify(pole) })
                    refetch()
                  }
                })
              }}
            />}
          </div>
        )
      }
    })

  return (
    <>
      <Row align='bottom' style={{ padding: '0 40px' }}>
        <Col span={12}>
          <Typography.Title style={{ fontWeight: 'bold' }}>{SERVICE_NAME[serviceName]}</Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right', paddingBottom: 20 }}>
          {serviceName === 'fullfillment' && <Button
            type='primary'
            size='large'
            onClick={() => navigate(`/services/${serviceName}/create`)}
            style={{ marginRight: 40 }}
          >
            Создать услугу
          </Button>}
          <Switch
            style={{
              transform: 'scale(140%)',
            }}
            checkedChildren='Активные'
            unCheckedChildren='Выполненные'
            checked={isActive}
            onChange={setIsActive}
          />
        </Col>
      </Row>
      <Table
        size='small'
        columns={columns}
        isLoading={isLoading}
        dataSource={serviceData}
        rowKey={({ id }) => id}
        onRow={(record, index) => ({
          onClick: (e) => {
            if (e.detail === 2) {
              navigate(`/services/${serviceName}/${record.id}`)
            }
          },
        })}
      />
      <Modal
        title='Изменить статус услуги'
        open={showStatusModal}
        width={300}
        onOk={async () => {
          if (!statusModalItem) return
          if (!statusModalDate) {
            alert('Не выбрана дата')
            return
          }
          const pole = {
            ...statusModalItem.pole,
            status: statusModalValue,
            [`date_status_${statusModalValue}`]: statusModalDate.format('DD.MM.YYYY'),
            is_finished: Number(statusModalValue === SERVICE_STATUS[serviceName]?.length - 1)
          }
          if (statusModalItem)
          await updateDatasetById(statusModalItem.id, { pole: JSON.stringify(pole) })
          refetch()
          if (pole?.type === 'issuance' && pole.is_finished) {
            const sendingId = (statusModalItem.placeData || [])[0]?.id_ref
            await checkSendingFinished(sendingId)
          }
          setShowStatusModal(false)
        }}
        onCancel={() => setShowStatusModal(false)}
      >
        <DatePicker
          size='large'
          value={statusModalDate}
          onChange={val => setStatusModalDate(val)}
          format='DD.MM.YYYY'
          style={{ width: '100%' }}
        />
        <br />
        <Select
          style={{ width: '100%', marginTop: 10 }}
          size='large'
          options={SERVICE_STATUS[serviceName]?.map((label, value) => ({ label, value }))}
          value={statusModalValue}
          onChange={val => setStatusModalValue(val)}
        />
      </Modal>
    </>
  )
}