import { useState, useRef } from 'react';
import { Spin, Alert, Row, Col, Divider, Space, Button, Menu, Table, Checkbox, Modal, notification, Typography } from 'antd';
import { PlusOutlined, CopyOutlined, DeleteOutlined, DoubleLeftOutlined, SaveOutlined, ExclamationCircleFilled } from '@ant-design/icons';
//~ import { useNavigate } from 'react-router-dom'
//~ import axios from '../../utils/axios'
import Wrapper from '../../../components/Wrapper';
import FormField from '../../../components/FormField'
//~ import DeleteButton from '../../components/DeleteButton'
import { NEW_ID } from '../../../config/consts';

import ENTITIES from '../../../config/entities.js';
import Groups from '..';

let objectsSorted = null;

const initObjects = () => {
	objectsSorted = Object.keys( ENTITIES )
		.sort( (a, b) => 
			( ENTITIES[a].description.topic < ENTITIES[b].description.topic ) || ( !!ENTITIES[a].description.topic && !ENTITIES[b].description.topic ) ? -1 :
			( ENTITIES[a].description.topic > ENTITIES[b].description.topic ) || ( !ENTITIES[a].description.topic && !!ENTITIES[b].description.topic ) ? 1 :
			ENTITIES[a].description.title < ENTITIES[b].description.title ? -1 :
			ENTITIES[a].description.title > ENTITIES[b].description.title ? 1 :
			0
		)
		.reduce( ( ret, key ) => {
			const topic = ENTITIES[ key ].description.topic || 'Прочее';
			ret[ topic ] ||= {};
			ret[ topic ][ key ] = ENTITIES[ key ];
			return ret;
		}, {} );
	return objectsSorted;
};

const emptyGroup = { name : '', acl : {} };

export default function UserGroups() {
	objectsSorted || initObjects();
	
	const store = Groups.useValue();
	const oldStore = useRef( null );
	
	const [ isGroupUpdated, setGroupUpdated ] = useState( false );
	const [ selectedGroupId, selectGroup ] = useState('');
	const [ modifiedGroup, setModifiedGroup ] = useState('');
	const [ selectedEntityId, selectEntity ] = useState('');
	const [ selectedACLId, selectACL ] = useState('');
	const [ groupNameCurrent, setGroupNameCurrent ] = useState('');
	const [ groupNameError, setGroupNameError ] = useState('');

	const selectedEntity = selectedEntityId ? ENTITIES[ selectedEntityId ].description : null;
	const selectedACL = modifiedGroup && selectedACLId ? modifiedGroup.acl[ selectedACLId ] : null;
	
	let currentGroupId = selectedGroupId;
	const getGroupUpdated = ( modifiedGroup, oldGroupId = currentGroupId ) => {
		if (!oldGroupId || !modifiedGroup) return false;
		const selectedGroup = oldGroupId === NEW_ID ? emptyGroup : store.groups[ oldGroupId ];
		if (selectedGroup.name !== modifiedGroup.name) return true;
		const origACLs = Object.keys( selectedGroup.acl );
		const newACLs = Object.keys( modifiedGroup.acl )
			.filter( key => { 
				if (!!ENTITIES[ key ].description.variants) return Object.keys( ENTITIES[ key ].description.variants ).some( variant => !!modifiedGroup.acl[ key ][ variant ]?.allow?.length );
				else return !!modifiedGroup.acl[ key ].allow?.length
			});
		if (origACLs.length !== newACLs.length) return true;
		for (const name of origACLs) {
			if (!modifiedGroup.acl[ name ]) return true;
			if (!!ENTITIES[ name ].description.variants) {
				const origVariants = Object.keys( selectedGroup.acl[ name ] );
				const newVariants = Object.keys( modifiedGroup.acl[ name ] )
					.filter( variant => !!modifiedGroup.acl[ name ][ variant ].allow?.length );
				if (origVariants.length !== newVariants.length) return true;
				for (const variant of origVariants) {
					if (!modifiedGroup.acl[ name ][ variant ]) return true;
					const origActions = selectedGroup.acl[ name ][ variant ].allow;
					const newActions = modifiedGroup.acl[ name ][ variant ].allow;
					if (origActions.length !== newActions.length) return true;
					const origObj = {};
					origActions.forEach( val => origObj[ val ] = true );
					if (newActions.some( val => !origObj[ val ] )) return true;
				}
			}
			else {
				const origActions = selectedGroup.acl[ name ].allow;
				const newActions = modifiedGroup.acl[ name ].allow;
				if (origActions.length !== newActions.length) return true;
				const origObj = {};
				origActions.forEach( val => origObj[ val ] = true );
				if (newActions.some( val => !origObj[ val ] )) return true;
			}
		}
		return false;
	};
	
	let actualModifiedGroup = modifiedGroup;
	let actualGroupUpdated = isGroupUpdated;
	if (store.lastLoad !== oldStore.current) {
		oldStore.current = store.lastLoad;
		
		if (selectedGroupId && selectedGroupId !== NEW_ID && !store.groups[ selectedGroupId ]) {
			currentGroupId = '';
			actualModifiedGroup = '';
			actualGroupUpdated = false;
			setTimeout( () => {
				selectGroup( '' );
				setModifiedGroup( '' );
				setGroupUpdated( false );
			} );
		}
		else {
			actualGroupUpdated = getGroupUpdated( modifiedGroup );
			setTimeout( () => setGroupUpdated( actualGroupUpdated ) );
		}
	}
	
	const updateGroup = ( newGroup, oldGroupId ) => {
		setModifiedGroup( newGroup );
		setGroupUpdated( getGroupUpdated( newGroup, oldGroupId ) );
	};
	
	const confirmUnsavedGroup = ( onOk, onCancel ) => {
		if (actualGroupUpdated) {
			const groupName = currentGroupId !== NEW_ID ? store.groups[ currentGroupId ].name || actualModifiedGroup.name : actualModifiedGroup.name;
			Modal.confirm({
				title : 'Забыть изменения?',
				icon : <ExclamationCircleFilled />,
				content : `Группа ${groupName} была изменена. Продолжить без сохранения изменений?`,
				okText : 'Продолжить',
				okType : 'danger',
				cancelText : 'Вернуться',
				onOk,
				onCancel
			});
		}
		else {
			onOk();
		}
	};

	const onSelectGroup = val => {
		if (val !== currentGroupId) {
			confirmUnsavedGroup(
				() => {
					const newGroup = JSON.parse( JSON.stringify( store.groups[ val ] || emptyGroup ) );
					updateGroup( newGroup, val );
					selectGroup( val );
					setGroupNameCurrent( newGroup.name );
					setGroupNameError( '' );
				}
			);
		}
	};
	
	const onCreateGroup = () => {
		confirmUnsavedGroup(
			() => {
				updateGroup( JSON.parse( JSON.stringify( emptyGroup ) ), NEW_ID );
				selectGroup( NEW_ID );
				setGroupNameCurrent( '' );
				setGroupNameError( 'Наименование группы должно быть заполнено' );
			}
		);
	};
	
	const onCopyGroup = () => {
		if (currentGroupId !== NEW_ID) {
			confirmUnsavedGroup(
				() => {
					const newGroup = JSON.parse( JSON.stringify( store.groups[ currentGroupId ] || emptyGroup ) );
					updateGroup( newGroup, NEW_ID );
					selectGroup( NEW_ID );
					setGroupNameCurrent( newGroup.name );
					setGroupNameError( 'Группа с таким названием уже есть' );
				}
			);
		}
	};
	
	const actualDeleteGroup = () => {
		Groups.delete( currentGroupId )
			?.catch( () => {
				notification.error({
					message : 'Не удалось удалить группу',
					placement : 'top',
					duration : 4
				});
			} );
	};
	
	const onDeleteGroup = () => {
		const groupName = store.groups[ currentGroupId ].name || actualModifiedGroup.name;
		Modal.confirm({
			title : 'Удалить группу?',
			icon : <ExclamationCircleFilled />,
			content : `Группа ${groupName} также будет удалена у всех пользователей. Вы уверены, что хотите удалить группу ${groupName}?`,
			okText : 'Удалить',
			okType : 'danger',
			cancelText : 'Оставить',
			onOk : actualDeleteGroup
		});
	};
	
	const onSaveGroup = () => {
		let promise;
		if (currentGroupId === NEW_ID) {
			promise = Groups.add( actualModifiedGroup.name, actualModifiedGroup.acl )
				.then( id => {
					const newGroup = JSON.parse( JSON.stringify( store.groups[ id ] || emptyGroup ) );
					updateGroup( newGroup, id );
					selectGroup( id );
					setGroupNameCurrent( newGroup.name );
					setGroupNameError( '' );
				} );
		}
		else {
			promise = Groups.modify( currentGroupId, actualModifiedGroup.name, actualModifiedGroup.acl );
		}
		promise?.catch( () => {
			notification.error({
				message : 'Не удалось сохранить группу',
				placement : 'top',
				duration : 4
			});
		} );
	};
	
	const updateGroupName = evt => {
		setGroupNameCurrent( evt.target.value );
		let nameError = '';
		const newName = evt.target.value.trim();
		if (!newName) {
			nameError = 'Наименование группы должно быть заполнено';
		}
		else {
			const allGroups = Object.keys( store.groups );
			if (allGroups.some( id => id !== currentGroupId && store.groups[ id ].name === newName )) {
				nameError = 'Группа с таким названием уже есть';
			}
		}
		setGroupNameError( nameError );
		
		const copiedGroup = { ...actualModifiedGroup };
		copiedGroup.name = newName;
		updateGroup( copiedGroup );
	};
	
	const appendACL = evt => {
		if (selectedEntity && actualModifiedGroup && !( selectedEntityId in actualModifiedGroup.acl )) {
			const copiedGroup = { ...actualModifiedGroup };
			if (!!ENTITIES[ selectedEntityId ].description.variants) {
				copiedGroup.acl[ selectedEntityId ] = Object.fromEntries(
					Object.keys( ENTITIES[ selectedEntityId ].description.variants )
						.map( variant => [ variant, { allow : [] } ] )
				);
			}
			else {
				copiedGroup.acl[ selectedEntityId ] = { allow : [] };
			}
			updateGroup( copiedGroup );
		}
	};
	
	const toggleACL = evt => {
		const copiedGroup = { ...actualModifiedGroup };
		let acl;
		if (evt.target['data-acl-variant']) {
			acl = copiedGroup.acl[ selectedACLId ][ evt.target['data-acl-variant'] ].allow;
		}
		else {
			acl = copiedGroup.acl[ selectedACLId ].allow;
		}
		if (evt.target.checked) {
			acl.push( evt.target['data-acl-name'] );
		}
		else {
			const idx = acl.indexOf( evt.target['data-acl-name'] );
			if (idx >= 0) acl.splice( idx, 1 );
		}
		updateGroup( copiedGroup );
	}
	
	let aclRows = [];
	let aclColumns = [];
	if (selectedACL) {
		if (!!ENTITIES[ selectedACLId ].description.variants) {
			aclColumns = [
				{
					title : 'Действие',
					dataIndex : 'action',
					onCell({ isVariant }) {
						if (isVariant) return { colSpan : 2 };
					},
					render( text, { isVariant } ) {
						if (isVariant) return <Typography.Text strong italic>{text}</Typography.Text>;
						else return text;
					}
				},
				{
					title : 'Разрешено',
					dataIndex : 'allow',
					width : 100,
					align : 'center',
					onCell({ isVariant }) {
						if (isVariant) return { colSpan : 0 };
					},
					render( isSet, { name, variant } ) {
						return <Checkbox data-acl-name={name} data-acl-variant={variant} checked={isSet} onChange={toggleACL} />
					}
				}
			];

			Object.keys( ENTITIES[ selectedACLId ].description.variants )
				.forEach( variant => aclRows.push(
					{ key : variant, isVariant : true, action : ENTITIES[ selectedACLId ].description.variants[ variant ] },
					...Object.entries( ENTITIES[ selectedACLId ].description.actions )
						.map( ([ name, item ]) => ({ key : `${variant}:${name}`, name, variant, action : item.title, allow : selectedACL[ variant ].allow.includes( name ) }) )
				) );
		}
		else {
			aclColumns = [
				{ title : 'Действие', dataIndex : 'action' },
				{ title : 'Разрешено', dataIndex : 'allow', width : 100, align : 'center', render( isSet, {key} ) {
					return <Checkbox data-acl-name={key} checked={isSet} onChange={toggleACL}/>
				} }
			];
			aclRows = Object.entries( ENTITIES[ selectedACLId ].description.actions )
				.map( ([ key, item ]) => ({ key, action : item.title, allow : selectedACL.allow.includes( key ) }) );
		}
	}
	
	return (
		<Wrapper
			title='Группы доступа'
		>
			<Spin size='large' spinning={store.isNull || store.isLoading}>
			{
				store.isFailed ? 
				(
					<Row style={{ padding : '0 20px', minHeight: 'calc(100vh - 64px)' }}>
						<Alert type='error' showIcon message='Ошибка' description='Не удалось загрузить группы.' />
					</Row>
				) :
				(
					<>
						<Row gutter={20} style={{ padding : '0 20px' }}>
							<Col span={10}>
								<FormField
									type='select'
									label='Группа доступа'
									placeholder='Выберите группу...'
									value={ currentGroupId === NEW_ID ? '' : currentGroupId }
									style={{ minWidth : '300px' }}
									onChange={ onSelectGroup }
									options={ Object.keys( store.groups ).map( id => ({ value: id, label : store.groups[id].name }) ) }
								/>
							</Col>
							<Col>
								<Space size={5}>
									<Button icon=<PlusOutlined /> title='Новая' onClick={onCreateGroup} />
									<Button icon=<CopyOutlined /> title='Создать копию' disabled={!currentGroupId || currentGroupId === NEW_ID} onClick={onCopyGroup} />
									<Button icon=<DeleteOutlined /> danger disabled={!currentGroupId || currentGroupId === NEW_ID} onClick={onDeleteGroup} title='Удалить' />
								</Space>
							</Col>
						</Row>
						<Divider style={{ marginLeft : '20px', marginRight : '20px' }} />
						<Row style={{ padding : '0 20px 20px 20px' }} >
						{ actualModifiedGroup && (
							<Col span={24}  style={{ maxWidth : '1024px' }}>
								<Row gutter={20}>
									<Col span={17}>
										<FormField
											type='text'
											label='Название группы:'
											validateStatus={ !groupNameError ? 'success' : 'error' }
											help={groupNameError}
											value={groupNameCurrent}
											onChange={updateGroupName}
										/>
									</Col>
									<Col>
										<Button type='primary' icon=<SaveOutlined /> disabled={ !actualGroupUpdated || !!groupNameError } onClick={onSaveGroup}>Сохранить</Button>
									</Col>
								</Row>
								<Row gutter={20} style={{ paddingTop : '20px'}}>
									<Col span={15}>
										<Row style={{ paddingBottom : '10px'}}>
											<label style={{ fontWeight : 'bold' }} >Управление доступом:</label>
										</Row>
										<Row style={{ paddingBottom : '20px'}}>
											<Menu
												mode='inline'
												onSelect={ evt => selectACL( evt.key ) }
												items={
													Object.keys( objectsSorted )
													.filter( topic => Object.keys( actualModifiedGroup.acl ).some( name => name in objectsSorted[ topic ] ) )
													.map( topic => ( {
														key : topic,
														label : topic,
														style : { fontWeight : 'bolder' },
														children :
															Object.keys( objectsSorted[ topic ] )
															.filter( name => name in actualModifiedGroup.acl )
															.map( name => ({ key : name, label : ENTITIES[ name ].description.title, style : { fontWeight : 'lighter', lineHeight : '2em', height : 'auto' } }) )
													} )
												) }
											/>
										</Row>
										<Row>
											<Table
												size='small'
												tableLayout='fixed'
												pagination={false}
												columns={aclColumns}
												dataSource={aclRows}
											/>
										</Row>
									</Col>
									<Col span={9}>
										<Row style={{ paddingBottom : '10px' }}>
											<label style={{ fontWeight : 'bold' }} >Все объекты:</label>
										</Row>
										<Row style={{ paddingBottom : '10px' }}>
											<Button
												icon=<DoubleLeftOutlined />
												disabled={ !selectedEntity || !actualModifiedGroup || selectedEntityId in actualModifiedGroup.acl }
												onClick={ appendACL }
											>Добавить</Button>
										</Row>
										<Row>
											<Menu
												mode='inline'
												onSelect={ evt => selectEntity( evt.key ) }
												items={
													Object.keys( objectsSorted )
													.map( topic => ( {
														key : topic,
														label : topic,
														style : { fontWeight : 'bolder' },
														children :
															Object.keys( objectsSorted[ topic ] )
															.map( name => ({ key : name, label : ENTITIES[ name ].description.title, style : { fontWeight : 'lighter', lineHeight : '2em', height : 'auto' } }) )
													} )
												) }
											/>
										</Row>
									</Col>
								</Row>
							</Col>
						) }
						</Row>
					</>
				)
			}
			</Spin>
		</Wrapper>
	)
}
