import { useState, useCallback, useMemo, useEffect, createElement } from 'react'
import { useNavigate, useLocation, useSearchParams, Link as RouterLink } from 'react-router-dom'
import { Button, Row, Table, Typography, Form, Modal, Checkbox, Dropdown, message } from 'antd'
import {
  SaveOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  CheckOutlined,
  DownloadOutlined,
  VerticalAlignTopOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import Cookies from 'universal-cookie'
import { useQueries } from 'react-query'
import { uniqBy, get as _get } from 'lodash'
import { BsTrash } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'
import axios from '../../utils/axios'
import { PropertyGap } from '../Sendings'
import FormField from '../../components/FormField'
import ServiceModal from '../../components/ServiceModal'
import { useDictionary, useUsersWithRole, getLastCurrencyRate, getLastId, getCount, createSending, updateSendingById, getSendingById, deleteSendingById, getPlacesBySendingId, deletePlaceById } from '../../utils/api'
import { getColumnSearchProps } from '../../utils/components'
import { API_URL, SENDING_STATUS, SERVICE_STATUS } from '../../config/consts'
import { required } from '../../utils/validationRules'
import { declOfNum, numberFormatter, localeNumber, filterOption, getSurnameWithInitials, getPaginationSettings } from '../../utils/utils'
import { sqlUpdate, sqlInsert } from '../../utils/sql'

const { Title, Link } = Typography
const cookies = new Cookies()

export default function Sending({ sendingId }) {
  const [ form ] = Form.useForm()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [ activeRow, setActiveRow ] = useState()
  const [ selectedRows, setSelectedRows ] = useState([])
  const [ selectedGroups, setSelectedGroups ] = useState([])
  const [ service, setService ] = useState({})
  const [ messageApi, contextHolder ] = message.useMessage()
  const isAir = searchParams.get('air') !== null

  const isNew = sendingId === 'create'
  const isEditPage = isNew || searchParams.get('edit') !== null
  const copy = searchParams.get('copy')

  const clients = useUsersWithRole(1)
  const drivers = useDictionary('drivers')

  const [ driverOptions, driverMap ] = useMemo(() => {
    if (!Array.isArray(drivers.data?.list)) return [[], {}]
    const options = drivers.data.list.map(({ pole = {}, ...item }) => ({
      value: item.id,
      label: item.label
    }))
    const map = options.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {})
    return [ options, map ]
  }, [drivers.data])

  const [ { isLoading, data, refetch }, places ] = useQueries([
    {
      queryKey: ['sending', sendingId, { copy }],
      queryFn: getSendingById(sendingId, { copy }),
      cacheTime: 0
    },
    {
      queryKey: ['places', sendingId],
      queryFn: getPlacesBySendingId(sendingId)
    },

  ])
  const rates = useDictionary('rates')
  const tarifs = useDictionary('prod-cat')

  const isNotSending = data?.json?.status === 0

  const [ clientsOptions, clientsMap, clientFilesMap ] = useMemo(() => {
    if (!Array.isArray(clients.data)) return [[], {}]
    const options = clients.data.map(({ json = {}, ...item }) => {
      const fullname = getSurnameWithInitials(item.family, item.name, item.middle)
      return {
        value: item.id_user,
        label: `${json?.code || ''}${fullname ? ` (${fullname})` : ''}`,
        dropbox_link_id: item.dropbox_link_id,
        dropbox_sending_id: item.dropbox_sending_id
      }
    })
    const map = options.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {})
    const files = options.reduce((acc, item) => ({ ...acc, [item.value]: 
      {
        dropbox_sending_id : item.dropbox_sending_id,
        dropbox_link_id : item.dropbox_link_id,    
      } }), {})

    return [ options, map, files ]
  }, [clients.data])

  const groupPlaces = useMemo(() => {
    if (!places.data || !Array.isArray(places.data)) return []
    return places.data.reduce((acc, place) => {
      const index = acc.findIndex(item => item.rate === place.rate && item.client === place.client)
      const size = place.size || {}
      const value = ((size.width * size.height * size.length) / 1000000).toFixed(3)
      if (index === -1) {
        acc.push({
          id: place.id,
          rate: place.rate,
          client: place.client,
          gross_weight: place.gross_weight,
          count: 1,
          invoice: place.invoice,
          invoiceId: place.invoice_id,
          pay_sum: place.pay_sum,
          hasMark: place.hasMark,
          value
        })
      } else {
        acc[index].count++
        acc[index].gross_weight += place.gross_weight
        acc[index].pay_sum += place.pay_sum
        acc[index].hasMark = acc[index].hasMark || place.hasMark
        acc[index].value = (parseFloat(acc[index].value) + parseFloat(value)).toFixed(3)
      }
      return acc
    }, [])
  }, [places.data])




 const handleFileUpload = async (event) => {
   event.preventDefault();
    const url = event.target.form.action; // dropbox API url

    const base64 = await convertBase64(event.target.files[0])
    let sending_data = JSON.parse(event.target.dataset.sending_data);
    debugger;
    var dl_id;
   const data = {
     token: cookies.get('token'), 
     u_hash: cookies.get('u_hash'), 
     file : JSON.stringify({
            'base64' : base64, 
            'name' : event.target.files[0].name, 
            'private' : -1
           }) 
   }
   
   // Загружаем файл
       await axios.postWithAuth(url, data,
      {headers: {'content-type': 'application/x-www-form-urlencoded'}}
      ).then(function (response) {
        if(response.status == 200) { //файл загружен
          dl_id = response.data.data.dl_id;
            }  
     })
   
   // Проверить, есть ли уже файл для этой позиции
   
       axios.select('dropbox_sendings_files', 'dropbox_link_id', 
          { where:  {
                      sending_id: sending_data.sending_id,
                      client_id: sending_data.client_id
                    }
          })
          .then(function (response) {
            if(response.status == 200) { debugger;
              if(response.data?.data?.[0]?.dropbox_link_id > 0) { // файл уже есть и надо заменить
              
              const response = axios.postWithAuth('/query/update', { sql: sqlUpdate('dropbox_sendings_files', 
                {
                  dropbox_link_id: dl_id
                },
                `sending_id= ${sending_data.sending_id} AND client_id = ${sending_data.client_id}`
                )});
            }
            else {     

               // файла нет и надо записать о нём данные
                axios.postWithAuth('/query/insert', { sql: sqlInsert('dropbox_sendings_files', 
                {
                  dropbox_link_id: dl_id, 
                  sending_id: sending_data.sending_id,
                  client_id: sending_data.client_id})
                }
                ).then(function (upload_response) {
               clientFilesMap[sending_data.client_id]=dl_id;
                                            debugger;
})
        

            }
  }


  })
  }

const handleFileDownload = (event) => {
  event.preventDefault();
    let sending_data = JSON.parse(event.target.closest("button").dataset.sending_data);
    const url = `${API_URL}/dropbox/file/${sending_data.dropbox_link_id}`;

   const data = {
     token: cookies.get('token'), 
     u_hash: cookies.get('u_hash'), 
}
    axios.postWithAuth(url, data,
      {headers: {'content-type': 'application/x-www-form-urlencoded'}}
      ).then(function (response) {debugger; 
          const [, filename] = response.headers.get('content-disposition').split('filename=');
debugger; 
        let blob = new Blob( [ response ], { type : response.headers.get('content-type') } );
  let url = URL.createObjectURL( blob );
  let a = createElement( 'a', { href : url, download : '1.txt' } );
  let e = new MouseEvent( 'click' );
  a.dispatchEvent( event );
  URL.revokeObjectURL( url );
            console.log(response); 
        })    
     }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const groupColumns = useMemo(() => [
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      render: id => <RouterLink to={`/clients/${id}`}>{clientsMap[id]}</RouterLink>,
      sorter: (a, b) => (clientsMap[a.client] || '').localeCompare(clientsMap[b.client] || ''),
      ...getColumnSearchProps('client', { options: clientsOptions })
    },
    {
      title: 'Мест',
      dataIndex: 'count',
      align: 'right',
      sorter: (a, b) => a.count - b.count
    },
    {
      title: 'ЧЗ',
      dataIndex: 'hasMark',
      render: hasMark => hasMark ? <CheckOutlined /> : ''
    },
    {
      title: 'Брутто',
      dataIndex: 'gross_weight',
      align: 'right',
      render: weight => Number(weight) ? Number(weight).toFixed(3) : '',
      sorter: (a, b) => a.gross_weight - b.gross_weight
    },
    {
      title: <>м<sup>3</sup></>,
      dataIndex: 'value'
    },
    {
      title: 'Сумма',
      dataIndex: 'pay_sum',
      align: 'right',
      render: val => Number(val) ? Number(val).toFixed(1) : ''
    },
    {
      title: 'Тариф',
      dataIndex: 'rate',
      key: 'rate',
      render: item => rates.data?.map[item]?.label,
      sorter: (a, b) => (a.rate || '').localeCompare(b.rate || ''),
      ...getColumnSearchProps('rate', { options: rates.data?.label })
    },
    {
      title: 'Счет',
      dataIndex: 'invoice',
      render: (invoice, item) => invoice && <RouterLink to={`/client-invoices/${item.invoiceId}`}>Счет № {invoice.number} от {dayjs(invoice.date).format('DD.MM.YYYY')}</RouterLink>
    },
    {
      title: 'Отчёт',
      render: (invoice, item) => (<form target='_blank' method='POST' action={`${API_URL}/script/template/1`}>
              <input type='hidden' value={`{"t_id": ${sendingId}, "c_id": ${item.client},  "cf": "vigruzka", "sf": "0", "da": 0 }`} name='s_t_data' />
              <input type='hidden' value={cookies.get('token')} name='token' />
              <input type='hidden' value={cookies.get('u_hash')} name='u_hash' />
              <button type='submit' style={{ background: 'none', border: 0, outline: 'none', padding: 0, cursor: 'pointer' }}>
                <DownloadOutlined
                  size={17}
                  color='#1677ff'
                />
              </button>
            </form>)
    }
,
    {
      title: 'Файл',
      render: (invoice, item) => (<>
      <form target='_blank' method='POST' action={`${API_URL}/dropbox/file/`}>
      <input type="file" accept="document/*" style={{ display: 'none' }} id={`contained-button-file-${item.client}`} onChange={handleFileUpload}  data-sending_data={`{"sending_id": ${sendingId}, "client_id": ${item.client}, "dropbox_link_id" : ${clientFilesMap[item.client]?.dropbox_link_id} }`}   />
      <label htmlFor={`contained-button-file-${item.client}`}>
                 <VerticalAlignTopOutlined
                  size={17}
                  color='#1677ff'
                />
      </label> 
     {clientFilesMap[item.client]?.dropbox_link_id &&
             <a href={`${API_URL}/dropbox/file/${clientFilesMap[item.client].dropbox_link_id}`} target="_blank">
              <button type="button" style={{ background: 'none', border: 0, outline: 'none', padding: 0, cursor: 'pointer' }}>
                <DownloadOutlined
                  size={17}
                  color='#1677ff'
                />
              </button>
              </a>
            } </form> 
            </>
          )
    }
  ], [clientsMap, clientsOptions, clientFilesMap])

  const placesData = (places.data || [])
    .map((item) => {
      return {
        ...item,
        buttons: (
          isNotSending && <div style={{ display: 'flex', gap: 10 }}>
            <CopyOutlined
              size={17}
              color='#141414'
              onClick={() => {
                navigate(`/sendings/${sendingId}/create?copy=${item.id}`)
              }}
            />
            <BsTrash
              style={{ marginLeft: 30, cursor: 'pointer' }} 
              size={17}
              color='red'
              onClick={async () => {
                const count = await getCount('dataset', { tip: 'product', id_ref: item.id })
                Modal.confirm({
                  title: 'Вы действительно хотите удалить это место?',
                  icon: <ExclamationCircleFilled />,
                  content: count > 0 && <div>
                    К этому месту {declOfNum(count, ['привязан', 'привязано', 'привязано'])} {count}&nbsp;
                    {declOfNum(count, ['товар', 'товара', 'товаров'])}, {count === '1' ? 'который' : 'которые'} так же&nbsp;
                    {count === '1' ? 'будет удален' : 'будут удалены'}
                  </div>,
                  okText: 'Да',
                  okType: 'danger',
                  cancelText: 'Нет',
                  onOk: () => deletePlaceById(item.id).then(() => places.refetch())
                })
              }}
            />
          </div>
        ),
      }
    })

  useEffect(() => {
    let newSelectedRows = []
    selectedGroups.forEach(group => {
      const place = placesData.find(item => item.id === group)
      const items = placesData.filter(item => item.client === place.client && item.rate === place.rate).map(item => item.id)
      newSelectedRows = newSelectedRows.concat(items)
    })
    setSelectedRows(newSelectedRows)
  }, [selectedGroups])

  const handleAddService = type => setService({ type, state: { selectedRows } })

  const createServiceDisabled = useMemo(() => {
    const selectedPlaces = selectedRows.map(rowId => placesData.find(item => item.id === rowId))
    const withActiveService = selectedPlaces.filter(item => !!item.service_id).length > 0
    const sameClient = uniqBy(selectedPlaces, 'client')
    return withActiveService || sameClient.length > 1
  }, [selectedRows, placesData])

  const cancelServiceSelected = useMemo(() => {
    const selectedPlaces = selectedRows.map(rowId => placesData.find(item => item.id === rowId))
    return selectedPlaces.filter(item => item.service_id).map(item => item.service_id)
  }, [selectedRows, placesData])

  const columns = useMemo(() => [
    {
      title: 'Место',
      dataIndex: 'place',
      key: 'place',
      align: 'right',
      sorter: (a, b) => a.place - b.place,
      ...getColumnSearchProps('place', { type: 'number' })
    },
    {
      title: 'Брутто',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      align: 'right',
      render: val => Number(val) ? localeNumber(Number(val).toFixed(3)) : '',
      sorter: (a, b) => a.gross_weight - b.gross_weight,
      ...getColumnSearchProps('gross_weight', { type: 'number' })
    },
    {
      title: 'ЧЗ',
      dataIndex: 'hasMark',
      render: hasMark => hasMark ? <CheckOutlined /> : ''
    },
    {
      title: 'Д',
      dataIndex: 'size',
      key: 'length',
      align: 'right',
      render: size => typeof size === 'object' ? size.length : '',
      sorter: (a, b) => a.size?.length - b.size?.length
    },
    {
      title: 'Ш',
      dataIndex: 'size',
      key: 'width',
      align: 'right',
      render: size => typeof size === 'object' ? size.width : '',
      sorter: (a, b) => a.size?.width - b.size?.width
    },
    {
      title: 'В',
      dataIndex: 'size',
      key: 'height',
      align: 'right',
      render: size => typeof size === 'object' ? size.height : '',
      sorter: (a, b) => a.size?.height - b.size?.height
    },
    {
      title: 'Категория',
      dataIndex: 'tarif',
      key: 'tarif',
      render: item => tarifs.data?.map[item]?.label,
      sorter: (a, b) => (a.tarif || '').localeCompare(b.tarif || ''),
      ...getColumnSearchProps('tarif', { options: tarifs.data?.label })
    },
    {
      title: 'Кол. товара',
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      sorter: (a, b) => a.count - b.count,
      ...getColumnSearchProps('count', { type: 'number' })
    },
    {
      title: 'Цена',
      dataIndex: 'tarif',
      key: 'tarifPrice',
      align: 'right',
      render: (t, item) => {
        const tarif = (tarifs.data?.list || []).find(item => item.id === t)
        if (!tarif) return ''
        const isForKs = tarif.price_type === '1'
        const count = isForKs ? item.gross_weight : item.count
        return (tarif.price_kg * count).toFixed(2)
      }
    },
    {
      title: 'Сумма',
      dataIndex: 'pay_sum',
      align: 'right',
      render: val => Number(val) ? Number(val).toFixed(1) : ''
    },
    {
      title: 'Статус',
      dataIndex: 'service',
      key: 'service_status',
      render: (service, item) => _get(SERVICE_STATUS, [item.service?.type, service?.status]),
      sorter: (a, b) => (a.service?.status || '').localeCompare(b.service?.status || '')
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      render: note => <div style={{ maxWidth: 80, maxHeight: 55, overflow: 'hidden', textOverflow: 'ellipsis' }} title={note}>{note}</div>,
      sorter: (a, b) => (a.note || '').localeCompare(b.note || '')
    },
    {
      title: '',
      dataIndex: 'buttons',
      key: 'buttons',
    },
  ], [clientsMap, clientsOptions, tarifs.data])
  const sendingTitle = `Отправка №${data?.from}`

  const handleSubmit = useCallback(async (values) => {
    const valuesMap = {
      from: values.from,
      create_datetime: dayjs(values.create_datetime).format('YYYY-MM-DD'),
      json: JSON.stringify({ ...data.json, ...values.json })
    }
    if (sendingId === 'create') {
      valuesMap.to = isAir ? '1' : '0'
      await createSending(valuesMap)
      const id = await getLastId('trip', 'id_trip')
      navigate(`/sendings/${id}`)
    } else {
      await updateSendingById(sendingId, valuesMap)
      messageApi.open({
        type: 'success',
        content: 'Запись успешно обновлена'
      })
      await refetch()
      setSearchParams({})
    }
  }, [sendingId, isAir, data, navigate, refetch, setSearchParams])

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 40px',
          gap: '20px',
        }}
      >
        <Row
          style={{
            gap: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography>
            <Title
              level={1}
              style={{ fontWeight: '700', marginBottom: '0' }}
            >
              {isNew ? 'Новая отправка' : sendingTitle}
            </Title>
            <Link
              onClick={() => navigate(`/sendings${data?.to === '1' ? '?air' : ''}`)}
              style={{ color: 'blue' }}
            >
              Отправка товаров <span> </span>
            </Link>
            &gt; 
            {isNew ? ' Создать отправку' : sendingTitle}
          </Typography>
          <Row
            style={{
              gap: 20,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginBottom: 20,
            }}
          >
            <form target='_blank' method='POST' action={`${API_URL}/script/template/1`}>
              <input type='hidden' value={`{"t_id": ${sendingId}, "cf": "vigruzka", "sf": "0", "da": 0 }`} name='s_t_data' />
              <input type='hidden' value={cookies.get('token')} name='token' />
              <input type='hidden' value={cookies.get('u_hash')} name='u_hash' />
              <Button
                icon={<DownloadOutlined />}
                size='large'
                htmlType='submit'
              >
                Отчет
              </Button>
            </form>
            {' '}
            {isEditPage ? (
              <>
                <Button
                  icon={<SaveOutlined />}
                  style={{
                    gap: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type='primary'
                  size='large'
                  onClick={() => {
                    form.submit()
                  }}
                >
                  Сохранить
                </Button>
                <Button
                  size={'large'}
                  style={{
                    gap: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type='primary'
                  danger
                  onClick={() => isNew ? navigate('/sendings') : setSearchParams({})}
                >
                  Отмена
                </Button>
              </>
            ) : (
              isNotSending && <>
                <Button
                  icon={<BiEdit />}
                  style={{
                    gap: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type='primary'
                  size={'large'}
                  onClick={() => {
                    navigate('?edit')
                  }}
                >
                  Редактировать
                </Button>
                <Button
                  size='large'
                  icon={<BsTrash />}
                  style={{
                    gap: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type='primary'
                  onClick={() => {
                    const count = placesData.length
                    Modal.confirm({
                      title: 'Вы действительно хотите удалить эту отправку?',
                      icon: <ExclamationCircleFilled />,
                      content: count > 0 && <div>
                        К этой отправке {declOfNum(count, ['привязана', 'привязано', 'привязано'])} {count}&nbsp;
                        {declOfNum(count, ['запись', 'записи', 'записей'])} о местах, {count === '1' ? 'которая' : 'которые'} так же&nbsp;
                        {count === '1' ? 'будет удалена' : 'будут удалены'}
                      </div>,
                      okText: 'Да',
                      okType: 'danger',
                      cancelText: 'Нет',
                      onOk: () => deleteSendingById(sendingId).then(() => navigate('/sendings'))
                    })
                  }}
                  danger
                >
                  Удалить
                </Button>
              </>
            )}
          </Row>
        </Row>

        <Row
          style={{
            display: 'flex',
            gap: `${PropertyGap}px`,
            borderRadius: 20,
            backgroundColor: '#FAFAFA',
            padding: 20,
            boxShadow: '0px 2px 4px 0px #00000026',
          }}
        >
          {!isLoading && (
            <Form
              style={{ display: 'block', width: '100%' }}
              layout='vertical'
              size='large'
              form={form}
              initialValues={data}
              onFinish={handleSubmit}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <FormField
                  width={120}
                  label='Номер'
                  name='from'
                  type='number'
                  isEdit={isEditPage}
                  rules={
                    [
                      ...required(),
                      () => ({
                        validator: async (_, id) => {
                          if (!isNew && parseInt(id) === parseInt(data.from)) return Promise.resolve()
                          const count = await getCount('trip', {
                            from: id,
                            canceled: 0,
                            'YEAR(create_datetime)': `YEAR('${dayjs().format('YYYY-MM-DD')}')`
                          })
                          return count > 0 ? Promise.reject(new Error('Отправка с таким номером уже существует')) : Promise.resolve()
                        },
                      })
                    ]
                  }
                />
                <FormField
                  width={200}
                  type='date'
                  label='Дата'
                  name='create_datetime'
                  isEdit={isEditPage}
                  text={data.create_datetime?.format('DD.MM.YYYY')}
                  rules={required()}
                />
                <FormField
                  width={720}
                  type='select'
                  label='Перевозчик'
                  name={['json', 'transporter']}
                  isEdit={isEditPage}
                  options={driverOptions}
                  text={driverMap && driverMap[data.json?.transporter]}
                  rules={required()}
                  filterOption={filterOption}
                  showSearch
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <FormField
                    type='date'
                    label='Дата отправки'
                    labelType='calc'
                    name='start_datetime'
                    width={150}
                    isEdit={isEditPage}
                    disabled={isEditPage}
                    text={data.start_datetime && data.start_datetime?.format('DD.MM.YYYY')}
                  />
                  <FormField
                    label='Мест'
                    labelType='calc'
                    type='number'
                    value={placesData.length}
                    width={100}
                    isEdit={false}
                    disabled={isEditPage}
                  />
                  <FormField
                    type='date'
                    label='Дата поступления'
                    labelType='calc'
                    name='complete_datetime'
                    width={150}
                    isEdit={isEditPage}
                    disabled={isEditPage}
                    text={data.complete_datetime && data.complete_datetime?.format('DD.MM.YYYY')}
                  />
                  <FormField
                    type='number'
                    label='Кол. товара'
                    labelType='sum'
                    name='count'
                    width={120}
                    isEdit={isEditPage}
                    disabled={isEditPage}
                    formatter={val => val === '' ? '0' : val}
                  />
                  <FormField
                    type='number'
                    label='Вес нетто'
                    labelType='sum'
                    name='net_weight'
                    width={120}
                    isEdit={isEditPage}
                    addonAfter={'кг'}
                    formatter={numberFormatter(3)}
                    disabled={isEditPage}
                  />
                  <FormField
                    type='number'
                    label='Вес брутто'
                    labelType='sum'
                    name='gross_weight'
                    width={120}
                    isEdit={isEditPage}
                    addonAfter={'кг'}
                    formatter={numberFormatter(3)}
                    disabled={isEditPage}
                  />
                  <FormField
                    type='select'
                    label='Статус'
                    labelType='calc'
                    name={['json', 'status']}
                    width={200}
                    isEdit={isEditPage}
                    disabled={isEditPage}
                    options={SENDING_STATUS.map((label, value) => ({ label, value }))}
                    text={SENDING_STATUS[data.json?.status]}
                  />
                </div>
              </div>
              <FormField
                width={1080}
                type='textarea'
                label='Примечание'
                name={['json', 'note']}
                isEdit={!isNotSending || isEditPage}
                text={data.json?.note}
              />
            </Form>
          )}
        </Row>
        {!isNew &&
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
                width: '100%',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '15px',
                  width: '100%',
                }}
              >
                <Title
                  level={1}
                  style={{ fontWeight: '700', marginBottom: '0' }}
                >
                  Места
                </Title>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  gap: '15px',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '100%',
                }}
              >
                {' '}
                {isNotSending && <Button
                  type='primary'
                  onClick={() => {
                    // editHandle(true)
                    navigate(location.pathname + `/create`)
                  }}
                  size={'large'}
                >
                  Создать
                </Button>}
                {data?.json?.status === 2 && <Button
                  type='primary'
                  size='large'
                  onClick={async () => {
                    let create = selectedGroups.filter(item => !groupPlaces.find(group => group.id === item)?.invoiceId)
                    if (create.length === 1) {
                      navigate('/client-invoices/create', { state: { type: 'sending', id: sendingId, group: create[0] } })
                      return
                    }
                    const response = await axios.select(
                      'dataset',
                      'max(cast(json_extract(pole, "$.number") as decimal)) as max',
                      {
                        where: {
                          status: 0,
                          tip: 'cl-invoice',
                          'YEAR(created_at)': `YEAR('${dayjs().format('YYYY-MM-DD')}')`
                        }
                      }
                    )
                    const rate = await getLastCurrencyRate('USD', dayjs().format('YYYY-MM-DD'))
                    const numberData = response.data?.data || []
                    const number = parseInt(_get(numberData, [0, 'max'])) || 0
                    create = create.map(id => placesData.find(place => place.id === id))
                    const vals = await Promise.all(create.map(async (item, i) => {
                      const places = placesData.filter(place => item.client === place.client && item.rate === place.rate)
                      let weight = 0
                      places.forEach(place => {
                        weight += Number(place.gross_weight)
                      })
                      const placeLikeInGroup = places[0]
                      const tarifRate = await axios.select('sprset', 'JSON_EXTRACT(pole, "$.price_kg") as price_kg', { where: { '$.pole.value': placeLikeInGroup?.rate } })
                      const tarif = (tarifRate.data?.data || [])[0]?.price_kg
                      const pole = {
                        number: number + i + 1,
                        parent_trip: sendingId,
                        client: item.client,
                        inclient: item.inclient,
                        name: `За отправку № ${data.from} от ${dayjs(data.create_datetime).format('DD.MM.YYYY')}, Мест: ${places.length}, Вес брутто: ${weight.toFixed(3)} кг.`,
                        pay_type: item.pay_type,
                        pay_usd: (tarif * weight).toFixed(2),
                        rate
                      }
                      return `(0, 'cl-invoice', '${dayjs().format('YYYY-MM-DD')}', '${JSON.stringify(pole)}')`
                    }))
                    await axios.postWithAuth('/query/insert', { sql:
                      `INSERT INTO dataset (status, tip, created_at, pole) VALUES ${vals.join(',')}`
                    })
                    places.refetch()
                  }}
                  disabled={!selectedGroups.filter(item => !groupPlaces.find(group => group.id === item)?.invoiceId).length}
                >
                  Создать счет
                </Button>}
                {data?.json?.status === 2 && <Dropdown
                  menu={{ items: [
                    {
                      key: 'issuance',
                      label: (
                        <span onClick={() => handleAddService('issuance')}>
                          Выдача со склада
                        </span>
                      )
                    },
                    {
                      key: 'delivery',
                      label: (
                        <span onClick={() => handleAddService('delivery')}>
                          Доставка
                        </span>
                      )
                    },
                    {
                      key: 'fullfillment',
                      label: (
                        <span onClick={() => handleAddService('fullfillment')}>
                          Фулфилмент
                        </span>
                      )
                    },
                    {
                      key: 'storage',
                      disabled: selectedRows.length > 1,
                      title: selectedRows.length > 1 && 'Хранение можно создать только для одного места',
                      label: (
                        <span onClick={() => navigate(`/services/storage/create`, { state: { selectedRows } })}>
                          Хранение
                        </span>
                      )
                    },
                    {
                      key: 'repack',
                      label: (
                        <span onClick={() => handleAddService('repack')}>
                          Переупаковка
                        </span>
                      )
                    }
                  ]}}
                  trigger={['click']}
                  disabled={selectedRows.length === 0 || createServiceDisabled}
                >
                  <Button
                    type='primary'
                    size='large'
                  >
                    Создать услугу
                  </Button>
                </Dropdown>}
                {data?.json?.status === 2 && <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    Modal.confirm({
                      title: 'Вы действительно хотите услуги выбранных мест?',
                      icon: <ExclamationCircleFilled />,
                      okText: 'Да',
                      okType: 'danger',
                      cancelText: 'Нет',
                      onOk: async () => {
                        await axios.postWithAuth('/query/update', { sql: `UPDATE dataset SET status=1 WHERE ${cancelServiceSelected.map(id => `id=${id}`).join(' OR ')}` })
                        places.refetch()
                      }
                    })
                  }}
                  disabled={selectedRows.length === 0 || cancelServiceSelected.length === 0}
                >
                  Отменить услугу
                </Button>}
              </Row>
            </div>
            <Table
              size='small'
              columns={groupColumns}
              dataSource={groupPlaces}
              isLoading={places.isLoading}
              rowKey={({ id }) => id}
              rowSelection={{
                type: Checkbox,
                onChange: selectedKeys => setSelectedGroups(selectedKeys)
              }}
              pagination={getPaginationSettings('sending_place')}
              expandable={{
                expandedRowRender: (record) => {
                  return <Table
                    size='small'
                    columns={columns}
                    isLoading={places.isLoading}
                    dataSource={placesData.filter(item => item.client === record.client && item.rate === record.rate)}
                    rowClassName={(r, index) => index === activeRow ? 'active-row' : ''}
                    rowKey={({ id }) => id}
                    onRow={(record, index) => ({
                      onClick: (e) => {
                        if (e.detail === 2) {
                          navigate(`${location.pathname}/${record.id}`)
                        } else {
                          setActiveRow(index)
                        }
                      },
                    })}
                    rowSelection={{
                      selectedRowKeys: selectedRows,
                      type: Checkbox,
                      onChange: selectedKeys => setSelectedRows(selectedKeys)
                    }}
                    pagination={false}
                  />
                }
              }}
            />
          </>
        }
      </div>
      {!!service?.type &&
        <ServiceModal
          client={selectedRows.map(rowId => placesData.find(item => item.id === rowId))[0]?.client}
          type={service?.type}
          placesId={selectedRows}
          onOk={id => navigate(`/services/${service.type}/${id}`, { state: id === 'create' ? service.state : {} })}
          onClose={() => setService()}
        />
      }
    </>
  )
}
