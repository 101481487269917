function menuList( key, label, icon, children ) {
	return { key, label, icon, children };
}

function menuLink( key, label, icon, object, action, variant = '' ) {
	return { key, label, icon, object, action, variant };
}

const MAIN_MENU = [
	menuList( "settings", "Настройки", "SettingOutlined", [
		menuLink( "settings-groups", "Группы", null, "groups", "view" ),
		menuLink( "settings-users", "Пользователи", null, "users", "list" ),
		menuLink( "settings-config", "Параметры учёта", null, "config", "view" )
	] ),
	menuList( "dictionaries", "Справочники", "FormOutlined", [
		menuLink( "dictionaries-employees", "Сотрудники", null, "employees", "list" ),
		menuLink( "dictionaries-clients", "Клиенты", null, "clients", "list" ),
		menuLink( "dictionaries-drivers", "Перевозчики", null, "drivers", "list" ),
		menuLink( "dictionaries-rates", "Тарифы перевозок", null, "rates", "list" ),
		menuLink( "dictionaries-currency", "Валюта и курс", null, "currency", "list" )
	] ),
	menuList( "sendings", "Отправки", "CarOutlined", [
		menuLink( "sendings-auto", "Авто", null, "sendings", "list", "road" ),
		menuLink( "sendings-avia", "Авиа", null, "sendings", "list", "air" )
	] ),
	menuList( "services", "Услуги", "UnorderedListOutlined", [
		menuLink( "services-issuance", "Выдача со склада", null, "issuance", "list" ),
		menuLink( "services-delivery", "Доставка", null, "delivery", "list" ),
		menuLink( "services-fullfillment", "Фулфилмент", null, "fullfillment", "list" ),
		menuLink( "services-storage", "Хранение", null, "storage", "list" ),
		menuLink( "services-repack", "Переупаковка", null, "repack", "list" )
	] ),
	menuList( "finances", "Финансы", "DollarOutlined", [
		menuList( "finances-client", "Клиенты", null, [
			menuLink( "finances-client-invoices", "Счета на оплату", null, "client-invoices", "list" ),
			menuLink( "finances-client-payments", "Оплаты", null, "client-payments", "list" ),
			menuLink( "finances-client-balance", "Баланс по клиентам", null, "client-balance", "view" )
		] ),
		menuList( "finances-drivers", "Перевозчики", null, [
			menuLink( "finances-drivers-invoices", "Счета перевозчика", null, "drivers-invoices", "list" ),
			menuLink( "finances-drivers-payments", "Оплаты перевозчику", null, "drivers-payments", "list" ),
			menuLink( "finances-drivers-balance", "Баланс по перевозчикам", null, "drivers-balance", "view" )
		] ),
		menuList( "finances-company", "Компании", null, [
			menuLink( "finances-company-income", "Приход средств", null, "company-income", "list" ),
			menuLink( "finances-company-cost", "Расход средств", null, "company-cost", "list" ),
			menuLink( "finances-company-balance", "Баланс компании", null, "company-balance", "view" )
		] )
	] )
];

export default MAIN_MENU;
